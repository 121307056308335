
export function getFavourites(eventId) {
    if(window.localStorage) {
        var favs = localStorage.getItem("favs"+ eventId)
        if(favs) {
            return JSON.parse(favs)
        }
    }
    
    return []
}

export function saveFavourites(favs, eventId) {
    localStorage.setItem("favs" + eventId, JSON.stringify(favs))
}