import React from 'react'
import $ from 'jquery'
import {Link, hashHistory} from 'react-router'

class SearchBox extends React.Component {
    constructor(props) {
        super(props)
        this.searchBox = null
    }
    
    submit() {
        var val = $(this.searchBox).val()
        if(val) {
            hashHistory.push("/search/" + encodeURIComponent(val))
        }
    }
    
    render() {
        return <form className="searchBox" onSubmit={() => this.submit()}>
            <input type="text" ref={(e) => this.searchBox = e} />
            <button type="submit" onclick={() => this.submit()}><i className="fa fa-search" aria-hidden="true"></i></button>
        </form>
    }
}

export default SearchBox
