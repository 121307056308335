export default function(allRunners, runners, func) {
    for (var i = 0; i < allRunners.length;) {
        var runner = allRunners[i]
        if(func(runner)) {
            var inserted = false
            for(var j in runners) {
                if(func(runner) < func(runners[j])) {
                    runners.splice(j, 0, runner)
                    inserted = true
                    break;
                }
            }

            if(!inserted) {
                runners.push(runner)
            }

            allRunners.splice(i, 1);
        }
        else {
            i++
        }
    }
}
