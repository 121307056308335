import React from 'react'
import SplitGrid from './SplitGrid'

class Club extends React.Component {
    render() {
        if(this.props.data) {
            var club = this.props.data.clubs[this.props.params.code]
            if(!club) {
                return <div>No club found</div>
            }

            return <div>
                <h1 className="margin">{this.props.params.code}</h1>
                {Object.keys(this.props.data.categories).map((categoryIndex) => {
                        var runners = club[categoryIndex]
                        var category = this.props.data.categories[categoryIndex]
                        if(runners) {
                            return <div>
                                <h2 className="margin">{category.title}</h2>
                                <SplitGrid category={category} runners={runners} config={this.props.data.config}/>
                            </div>
                        }
                })}
            </div>
        }
        else {
            return <div>Loading...</div>
        }
    }
}

export default Club
