
export function renderTime(ms) {
    if(isNaN(ms) ) {
        ms = parseInt(ms)
    }

    if(!ms) {
        return null;
    }

    var h = Math.floor(ms / (1000 * 60 * 60))
    var min = Math.floor((ms - h * 1000 * 60 * 60) / (1000 * 60))
    if(min < 10) {
        min = "0" + min
    }
    var s = Math.floor((ms - h * 1000 * 60 * 60 - min * 1000 * 60) / 1000)
    if(s < 10) {
        s = "0" + s
    }
    var returnValue = "";
    if(h > 0) {
        returnValue = h + ":"
    }
    return returnValue + min + ":" + s
}

export function renderDayTime(ms) {
    if(isNaN(ms) ) {
        ms = parseInt(ms)
    }
    
    if(!ms) {
        return null;
    }

    var h = Math.floor(ms / (1000 * 60 * 60))
    var min = Math.floor((ms - h * 1000 * 60 * 60) / (1000 * 60))
    if(min < 10) {
        min = "0" + min
    }
    var s = Math.floor((ms - h * 1000 * 60 * 60 - min * 1000 * 60) / 1000)
    if(s < 10) {
        s = "0" + s
    }

    if(h < 10) {
        h = "0" + h
    }

    return h + ":" + min + ":" + s
}
