import React from 'react'
import _ from 'lodash'
import SplitGrid from './SplitGrid'

class Country extends React.Component {
    render() {
        function escapeRegExp(str) {
            return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
        }
        
        if(this.props.data) {
            var term = this.props.params.term
            var terms = []
            var currentString = "";
            for(var i in term) {
                var char = term[i]
                if([" ", ","].indexOf(char) > -1) {
                    if(currentString.length > 0) {
                        terms.push(currentString)
                    }
                    currentString = ""
                }
                else {
                    currentString += char
                }
            }
            if(currentString.length > 0) {
                terms.push(currentString)
            }
            var expressions = []
            for(var i in terms) {
                var escapedTerm = escapeRegExp(terms[i]) 
                expressions.push(new RegExp(escapedTerm, "i"))  
            } 
            
            return <div>
                <h1 className="margin">Search: {term}</h1>
                {Object.keys(this.props.data.categories).map((categoryIndex) => {
                        var category = this.props.data.categories[categoryIndex]
                        
                        var runners = _.filter(category.runners, (r) => {
                            var expressionMatchedAny = false;
                            for(var i in expressions) {
                                var expression = expressions[i]
                                
                                if(r.runner.name && r.runner.name.match(expression)) {
                                    expressionMatchedAny = true;
                                }
                                if(r.runner.club && r.runner.club.match(expression)) {
                                    expressionMatchedAny = true;
                                }
                            }
                            return expressionMatchedAny;
                        })
                        
                        if(runners && runners.length > 0) {
                            return <div>
                                <h2 className="margin">{category.title}</h2>
                                <SplitGrid category={category} runners={runners} config={this.props.data.config}/>
                            </div>
                        }
                })}
            </div>
        }
        else {
            return <div>Loading...</div>
        }
    }
}

export default Country
