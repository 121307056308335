import React from 'react'
import DataService from '../data/DataService'
import SplitGrid from './SplitGrid'

import fancySort from '../helpers/fancySort'


class Category extends React.Component {
    componentDidMount() {

    }
    render() {
        if(this.props.data) {
            var category = this.props.data.categories[this.props.params.categoryId]

            if(category.autoFollow) {
                return this.renderAutoFollow(category)
            }

            /*var allRunners = _.filter(category.runners, r => r.runner.classification == 0).slice()
            var runners = []

            var finishIndex = _.findIndex(category.splits, s => s.code == "finish")
            for(var i = finishIndex; i >= 0; i--) {
                var func = (r) => r.splits[i].time;
                fancySort(allRunners, runners, func)
            }

            allRunners = _.orderBy(allRunners, (r) => r.runner.runnerStartTime)
            for(var i in allRunners) {
                runners.push(allRunners[i])
            }

            var ncRunners = _.filter(category.runners, r => r.runner.classification != 0)
            for(var i in ncRunners) {
                runners.push(ncRunners[i])
            }*/

            return <div className="categoryContainer">
                <SplitGrid category={category} runners={category.runners} select={this.props.params.column} config={this.props.data.config}/>
            </div>
        }
        else {
            return <div>Loading...</div>
        }
    }
    
    
    changeSplit(code) {
        if(code == "all") {
         //   hashHistory.push("/category/" + encodeURIComponent(this.props.params.categoryId))
        }
        else {
            if(this.props.onChangeSplit) {
                this.props.onChangeSplit(code)
            }
        }
    }
    

    renderAutoFollow(config) {
        var categories = []
        for(var i in config.autoFollow) {
            categories.push(this.props.data.categories[config.autoFollow[i]])
        }

        var autoCategory;
        var autoSplit;
        for(var i = categories.length - 1; i >= 0; i--) {
            var category = categories[i]
            
            for(var j = category.splits.length - 1; j >= 0; j--) {
                var split = category.splits[j]
                if(split.code == "legTime") {
                    continue;
                }
                if(_.some(split.runners, r => !!r.time)) {
                    autoSplit = split
                    autoCategory = category
                    break;
                }
            }

            if(autoCategory) {
                break;
            }
        }
        
        if(!autoCategory) {
            autoCategory = categories[0]
        }
        if(!autoSplit) {
            autoSplit = autoCategory.splits[0]
        }

        return <div>
            <h2 className="margin">{autoCategory.title} ({autoSplit.title})</h2>
            <div className="margin">Note: Auto-follow will automatically change to the next avaible leg/split when the first runner passes it.</div>
            <SplitGrid category={autoCategory} runners={autoCategory.runners} splitExternalLink={true} select={"split-"+ autoSplit.code} config={this.props.data.config}/>
        </div>
    }
}

export default Category
