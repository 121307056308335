import React from 'react'
import DataService from '../data/DataService'
import {renderDayTime} from '../helpers/time'
import SearchBox from './SearchBox'
import {Link} from 'react-router'
import $ from 'jquery'



class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null
        }
        this.stateFile = null
        this.initStateFile = false
    }

    componentDidMount() {
        // BAD: Usage of global variable which needs to be specified in HTML
        this.dataService = new DataService(RSLiveConfig.configUrl, (data) => this.update(data))
        setInterval(() => this.dataService.updateData(), 5000)
    }

    update(data) {
        this.setState({data: data})
        var titles = document.getElementsByTagName("title");
        titles[0].innerText = "Live: " + data.config.racetitle;
        this.initStateLine(data.config.stateFile)
    }
    
    initStateLine(file) {
        this.stateFile = file
        if(!this.initStateFile){
            this.initStateFile = true
            var func = () => {
                if(this.stateFile) {
                    $.get(this.stateFile, (data) => {
                        this.setState({status: data})
                    })
                }
            }
            setInterval(func, 20000)
            func()
        } 
    }

    renderLastUpdate() {
        if(!this.state.data) return;

        return <div className="lastUpdate">
            {this.state.data.config.date}
            <span> (updated: </span>
            {this.state.data.updated}
            , auto-refresh enabled)
            <span className="status"> {this.state.status}</span>
        </div>
    }

    render() {
        return <div>
            <header>
                <div className="title">{!this.state.data ? "" : this.state.data.config.racetitle}</div>
                <div className="nav">
                    <nav>
                        <ul>
                            <li><Link to="/favourites">My Favourites</Link></li>
                        </ul>
                    </nav>
                    <SearchBox />
                    
                </div>
            </header>
            <div className="content">
                {this.renderLastUpdate()}

                {React.cloneElement(this.props.children, {data: this.state.data})}
            </div>
        </div>;
    }
}

export default App
