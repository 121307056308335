import $ from 'jquery'
import _ from 'lodash'
import countriesMap from '../helpers/countries'


function updateSplits(category) {
    for(var i in category.splits) {
        var split = category.splits[i]
        var runners = _.map(category.runners, function(r) {
            return r.splits[i]
        })
        split.runners = _.orderBy(runners, function(r) {
            return r.time
        })

        var classedRunners = _.filter(split.runners, r => r.runner.classification == 0)
        for(var j in classedRunners) {
            var runner = classedRunners[j]
            if(j > 0) {
                if(split.runners[j-1].time == runner.time) {
                    runner.rank = split.runners[j-1].rank
                }
                else {
                    runner.rank = 1 + j / 1
                }
            }
            else {
                runner.rank = 1
            }

            runner.back = runner.time - classedRunners[0].time
        }
    }
}

function getNewLevel(startTime, raceTime, config) {
    if(!config.activateNewLevel) return -1;

    startTime = startTime / 1 - (config.minutesOffsetToUTC * 60 * 1000)

    var now = new Date()
    var utcNow = (((((now.getUTCHours() * 60)
        + now.getUTCMinutes()) * 60)
            + now.getUTCSeconds()) * 1000)


    var newInterval = 2 * 60 * 1000
    var time = startTime / 1 + raceTime / 1
    var diff =  utcNow - time

	if (diff <= newInterval && diff >= 0) {
		return diff / (newInterval / 3);
	}

	return -1;
}

function updateLast(category) {
    for(var i in category.runners) {
        var runner = category.runners[i]
        var finish = _.find(runner.splits, s => {return s.split.code == "finish"})
        if(finish.time) {
            runner.last = finish;
            runner.runner.rank = finish.rank
        }
        else {
            runner.last = _.findLast(runner.splits, s => {return s.time})
        }
    }
}

export default class {
    constructor(configUrl, onData) {
        this.configUrl = configUrl;
        this.onData = onData
        this.init()
    }

    init() {
        $.getJSON(this.configUrl, (config) => {
            this.config = config
            this.updateData();
        })
    }

    updateData() {
        var categories = {}
        var runners = []
        var predicates = []
        var updateTime = null;
        for(var url in this.config.files) {
            var file = this.config.files[url]
            predicates.push(this.getDataFromFile(url, file, (cats, runs, updateTimeFromFile) => {
                updateTime = updateTimeFromFile
                for(var i in cats) {
                    categories[cats[i].url || i] = cats[i]
                }
                for(var i in runs) {
                    runners.push(runs[i])
                }
            }));
        }
        
        $.when.apply($, predicates).then(() => {
            
            var countryCodes = _.groupBy(runners, (r) => r.runner.federation)
            var countries = _.mapValues(countryCodes, function(runners) {
                return _.groupBy(runners, function(r) {
                    return r.category.code
                })
            })

            var clubsMap = _.groupBy(runners, (r) => r.runner.club)
            var clubs = _.mapValues(clubsMap, function(runners) {
                return _.groupBy(runners, function(r) {
                    return r.category.code
                })
            })
            this.onData({categories, countries, clubs, runners, config: this.config, updated: updateTime})

        })
    }
    
    getDataFromFile(url, file, callback) {
        return $.getJSON(url, (data) => {
            var categories = {}
            var runners = []
                
            for(var i in file.classes) {
                var category = file.classes[i]
                if(category.autoFollow) {
                    categories[category.classcode] = {
                        url: category.url,
                        code: category.url || category.classcode,
                        title: category.title,
                        autoFollow: category.autoFollow
                    }
                }
                else {
                    categories[category.classcode] = {
                        url: category.url,
                        code: category.url || category.classcode,
                        splits: _.clone(category.radioControls),
                        runners: [],
                        title: category.title
                    }
                    categories[category.classcode].splits.push({
                        title: "Finish",
                        code: "finish"
                    })

                    if(category.raceTime) {
                        categories[category.classcode].splits.push({
                            title: "Leg Time",
                            code: "legTime"
                        })
                    }
                }
            }
            

            for(var i in data.rows) {
                var runner = data.rows[i].cell
                var cat = categories[runner.class]
                if(cat) {
                    var splits = _.map(cat.splits, (s) => {
                        var time = runner[s.code]
                        if(s.code == "finish") {
                            time = runner.raceTime
                        }
                        if(s.code == "legTime" && runner.raceTime) {
                            time = runner.raceTime - (runner.runnerStartTime - runner.startTime)
                        }

                        if(runner.club) {
                            if(this.config.replaceCountryCodeClub) {
                                for(var j in countriesMap) {
                                    var country = countriesMap[j]
                                    runner.club = runner.club.replace(country, j)
                                }
                            }
                        }


                        return {
                            split: s,
                            time: parseInt(time),
                            runner: runner,
                            newLevel: getNewLevel(runner.startTime, time, this.config)
                        }
                    })

                    var runner = {
                        runner: runner,
                        splits: splits,
                        category: cat
                    }
                    cat.runners.push(runner)
                    runners.push(runner)
                }
            }

            for(var i in categories) {
                updateSplits(categories[i])
                updateLast(categories[i])
            }
            
            callback(categories, runners, data.updatetime)
        })
    }
};
