import React from 'react'
import {Link, hashHistory} from 'react-router'

class CategoryNav extends React.Component {
    constructor(props) {
        super(props)
        this.state = {selectedSplit: null}
    }

    changeCat(id) {
        hashHistory.push("/category/" + encodeURIComponent(id))
    }
    render() {
        if(this.props.data) {
            var categories = this.props.data.categories
            
            return <div >
                <ul className="cat-nav">
                    {Object.keys(categories).map((id) => {
                        var selected = this.props.params.categoryId == id
                        var urlId = encodeURIComponent(id);
                        return <li key={id}  className={selected ? "selected" : ""}>
                            <Link to={`/category/${urlId}`}>{categories[id].title}</Link>
                        </li>
                    })}
                </ul>
                <div className="cat-nav-mobile">

                    <select value={this.props.params.categoryId ? this.props.params.categoryId : ""} onChange={(event) => this.changeCat(event.target.value)}>
                        {(() => {
                            if(!this.props.params.categoryId) {
                                return <option value="">Choose a category...</option>
                            }
                        })()}

                        {Object.keys(categories).map((id) => {
                            return <option key={id} value={id}>{categories[id].title}</option>
                        })}
                    </select>
                </div>

                {React.cloneElement(this.props.children, {data: this.props.data, selectedSplit: this.state.selectedSplit})}
            </div>
        }
        else {
            return <div>Loading...</div>
        }
    }

}

export default CategoryNav;
