import React from 'react'
import SplitGrid from './SplitGrid'
import {getFavourites} from '../helpers/favourites'

class Favourites extends React.Component {
    render() {
        if(this.props.data) {
            var favourites = getFavourites(this.props.data.config.racetitle)
            if(favourites.length == 0) {
                return <div>No favourites found</div>
            }

            return <div>
                <h1 className="margin">Favourites</h1>
                {Object.keys(this.props.data.categories).map((categoryIndex) => {
                        var category = this.props.data.categories[categoryIndex]
                        var runners = _.filter(category.runners, (r) => favourites.indexOf(r.runner.id) > -1)
                        if(runners.length > 0) {
                            return <div>
                                <h2 className="margin">{category.title}</h2>
                                <SplitGrid category={category} runners={runners} config={this.props.data.config}/>
                            </div>
                        }
                })}
            </div>
        }
        else {
            return <div>Loading...</div>
        }
    }
}

export default Favourites
