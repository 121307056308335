import { Router, Route, Link,IndexRoute, Redirect, hashHistory } from 'react-router'
import React from 'react'
import { render } from 'react-dom'
import App from './components/App'
import CategoryIndex from './components/CategoryIndex'
import CategoryNav from './components/CategoryNav'
import Category from './components/Category'
import Country from './components/Country'
import Club from './components/Club'
import Search from './components/Search'
import Favourites from './components/Favourites'



class NoMatch extends React.Component {
  render() {
    return <div>
     notmatch
    </div>
  }
}

render(
    <Router history={hashHistory}>
        <Redirect from="/" to="/category"/>
      <Route path="/" component={App}>
        <Route path="/category" component={CategoryNav}>

            <IndexRoute component={CategoryIndex} />
            <Route path="/category/:categoryId" component={Category} />
            <Route path="/category/:categoryId/:column" component={Category} />
            <Route path="/country/:code" component={Country}/>
            <Route path="/club/:code" component={Club}/>
            <Route path="/search/:term" component={Search}/>
            <Route path="/favourites" component={Favourites}/>
        </Route>
        <Route path="*" component={NoMatch}/>
      </Route>
    </Router>,
  document.getElementById('content')
);
