import React from 'react'
import {Link} from 'react-router'
import {renderTime, renderDayTime} from '../helpers/time'
import {getFavourites, saveFavourites} from '../helpers/favourites'
import fancySort from '../helpers/fancySort'
           
var classificationCode = ["OK", "DNS", "DNF", "MP", "DQ", "TE", "NK"]; // NK: not known


class SplitGrid extends React.Component {
    constructor(props) {
        super(props)
        this.state = {openRunners: [], favourites: []}
        this.virutalTableHeader = null
        this.grid = null
    }

    componentDidMount() {
        var favs = getFavourites(this.props.config.racetitle)
        if(favs) {
            this.setState({favourites: favs})
        }
    }

    toggleFavourite(id) {
        var favs = this.state.favourites
        if(favs.indexOf(id) > -1) {
            this.setState({favourites:
                favs.splice(favs.indexOf(id),1)
            })
        }
        else {
            favs.push(id)
        }
        this.setState({favourites: favs})

        this.saveFavourites()
    }
    saveFavourites() {
        saveFavourites(this.state.favourites ,this.props.config.racetitle);
    }
    
    sortRunners(runnersToSort, col, category) {
        var allRunners = _.filter(runnersToSort, r => r.runner.classification == 0).slice()
        var runners = []

        if(col.startsWith("split-")) {
            var code = col.substring("split-".length)
            var index = _.findIndex(category.splits, (s) => s.code == code)

            var func = (r) => r.splits[index].time;
            fancySort(allRunners, runners, func)

            var finishIndex = _.findIndex(category.splits, s => s.code == "finish")
            for(var i = finishIndex; i >= 0; i--) {
                var func = (r) => r.splits[i].time;
                fancySort(allRunners, runners, func)
            }
        }

        allRunners = _.orderBy(allRunners, (r) => r.runner.runnerStartTime)
        for(var i in allRunners) {
            runners.push(allRunners[i])
        }

        var ncRunners = _.filter(runnersToSort, r => r.runner.classification != 0)
        for(var i in ncRunners) {
            runners.push(ncRunners[i])
        }
        return runners;
    }
    render() {
        var category = this.props.category
        var runners = this.props.runners
        
        runners = this.sortRunners(runners, this.getSelectedColumn(), category)
        
        return <div className="splits">
            {this.renderSplitsNav(category)}
            <div className="split-grid-container">
            <table className="split-grid">
                {this.renderHeader(category)}
                <tbody>
                    {runners.map(r => this.renderRunner(r, this.props.config))}
                </tbody>
        </table>
        </div>
        </div>
    }
    
    renderSplitsNav(category) {
        if(!category) return;
        if(!category.splits) return;

        return <div className="split-nav-mobile">
            <select onChange={(event) => this.setSortCol(event.target.value)} value={this.state.sortCol}>
                <option value="">Finish</option>
                {category.splits.map((s) => {
                    return <option key={s.code}  value={`split-${s.code}`}>{s.title}</option>
                })}
            </select>
        </div>
    }

    onRowClick(id) {
        var openRunners = this.state.openRunners
        if(openRunners.indexOf(id) > -1 ) {
            openRunners.splice(openRunners.indexOf(id), 1)
        }
        else {
            openRunners.push(id)
        }

        this.setState({openRunners: openRunners})
    }
    
    linkSplit(splitCode, title) {
        if(this.props.splitExternalLink) {
            return <Link to={`/category/${encodeURIComponent(this.props.category.code)}/${encodeURIComponent(splitCode)}`}>
                {title}
            </Link>
        }
        else {
            return <a onClick={() => this.setState({sortCol: splitCode})}>{title}</a>
        }
    }
    
    getSelectedColumn() {
        return this.state.sortCol || this.props.select ||  "split-finish"; 
    }
    
    setSortCol(sortCol) {
        this.setState({sortCol: sortCol})
    }
    
    renderHeader(category) {
        var urlCatId = encodeURIComponent(category.code)
        var getSelect = (id) => id == this.getSelectedColumn() ? "selected" : ""
        return <thead>
        <tr>
        <th></th>
        <th></th>
            <th>Name</th>
            <th style={this.props.config.showClub ? {} : {display: "none"}}>
                Club
            </th>
            <th style={this.props.config.showCountry ? {} : {display: "none"}}>
                Country
            </th>

            <th className={getSelect("start")}>{this.linkSplit("start", "Starttime")}</th>
            {category.splits.map((s) => {
                var urlCode = encodeURIComponent(s.code)
                return <th key={s.code}  className={getSelect("split-" + s.code)}>
                    {this.linkSplit("split-" + s.code, s.title)}
                </th>
            })}
        </tr>
        </thead>
    }
    getNewLevelStyle(s) {
        return s.newLevel > -1 ?
            {backgroundColor: "rgb(255," + (169 + Math.floor(20 * s.newLevel)) + ",0)"}
            : {}

    }
    renderSplit(s, runner, collapsible) {
        var newStyle = this.getNewLevelStyle(s)
        if(s.split.code == "legTime" && runner.runner.classification  != 0) {
            return <td key={s.split.code}></td>
        }
        if(s.split.code == "finish" && runner.runner.classification  != 0) {
            
            return <td key={s.split.code} className={collapsible} style={newStyle}>
                <span className="splitLabel">
                    {this.linkSplit("split-" + s.split.code, s.split.title)}
                </span>
                <span>
                    {classificationCode[runner.runner.classification]}
                </span>
            </td>
        }

        if(!s.time) return <td key={s.split.code}></td>

        return <td key={s.split.code} className={collapsible} style={newStyle}>
            <span className="splitLabel">
                    {this.linkSplit("split-" + s.split.code, s.split.title)}
            </span>
            <span className="timeLine">{renderTime(s.time)}
                <span style={s.rank ? {} : {display: "none"}}> ({s.rank})</span>
            </span>

            <span className="timeBack" style={s.back ? {} : {display: "none"}}>+{renderTime(s.back)}</span>
        </td>
    }

    renderRunner(runner, config) {
        var collapsible = this.state.openRunners.indexOf(runner.runner.id) > -1 ?
                          "collapsible-show" :
                          "collapsible";

        var mainSplit;
        var showMainSplitRank = false
        if(this.getSelectedColumn().startsWith("split-")) {
            var splitCode = this.getSelectedColumn().substring("split-".length)
            mainSplit = _.find(runner.splits, (s) => s.split.code == splitCode);
            showMainSplitRank = true;
        }

        if(!mainSplit || !mainSplit.time || (mainSplit.split.code == "finish" && runner.runner.classification != 0)) {
            showMainSplitRank = false
            
            if(runner.runner.classification && runner.runner.classification !=  0) {
                mainSplit = {code: "classification", classifiction: runner.runner.classification}
            }
            else if(runner.last) {
                mainSplit = runner.last;
            }
            else {
                mainSplit = {code: "start", startTime: runner.runner.runnerStartTime}
            }
        }

        var isFav = this.state.favourites.indexOf(runner.runner.id) > - 1
        var favClass = isFav ? 
                        "fav" : "";

        var mainSplitStyle = this.getNewLevelStyle(mainSplit);

        return (<tr
                key={runner.runner.id}
                onClick={() => this.onRowClick(runner.runner.id)}
                className={favClass}>
            <td className="mobileRow">
                <span onClick={(event) => {this.toggleFavourite(runner.runner.id); event.stopPropagation()}} className="favAdder">
                    <i className={isFav ? "fa fa-star" : "fa fa-star-o"}></i>
                </span>
                <div className="row">
                    <span className="country">
                        <Link to={`/country/${encodeURIComponent(runner.runner.federation)}`}>
                            {runner.runner.federation}
                        </Link>
                    </span>
                    <span className="name">
                        {showMainSplitRank ? mainSplit.rank : runner.runner.rank}
                        <span>{(showMainSplitRank ? mainSplit.rank : runner.runner.rank) ? "." : ""} </span>
                         
                        {runner.runner.name}
                         <span style={{"display": (runner.runner.startnumber ? "inline" : "none")}}>
                            {" (" + runner.runner.startnumber})
                        </span>
                    </span>
                </div>
                <div className="row" style={mainSplitStyle}>
                    {(() => {
                        if(mainSplit.code == "start") {
                            return <div>
                                <span className="lastSplit">
                                    Start
                                </span>
                                <span className="lastSplitTime">
                                    {renderDayTime(mainSplit.startTime)}
                                </span>
                            </div>
                        }
                        else if(mainSplit.code == "classification") {
                            return <div>
                                <span className="lastSplit">
                                    Finish
                                </span>
                                <span className="lastSplitTime">
                                    {classificationCode[mainSplit.classifiction]}
                                </span>
                            </div>
                        }
                        else {
                            return <div>
                                <span className="lastSplit">
                                    {mainSplit.split.title}
                                </span>
                                <span className="lastSplitTime">
                                    {renderTime(mainSplit.time)}
                                    <span style={mainSplit.rank ? {} : {display: "none"}}> ({mainSplit.rank}.)</span>
                                </span>
                            </div>
                        }
                    })()}
                </div>
            </td>
            <td className="firstRow" onClick={() => this.toggleFavourite(runner.runner.id)}>
                <i className={isFav ? "fa fa-star" : "fa fa-star-o"}></i>

            </td>
            <td>
                {runner.runner.rank}
            </td>
            <td className="nameRow">
                {runner.runner.name} 
                <span style={{"display": (runner.runner.startnumber ? "inline" : "none")}}>
                    {" (" + runner.runner.startnumber})
                </span>
            </td>
            <td style={config.showClub ? {} : {display: "none"}}>
                <Link to={`/club/${encodeURIComponent(runner.runner.club)}`}>
                    {runner.runner.club}
                </Link>
            </td>
            <td style={config.showCountry ? {} : {display: "none"}}>
                <Link to={`/country/${encodeURIComponent(runner.runner.federation)}`}>
                    {runner.runner.federation}
                </Link>
            </td>
            <td className={collapsible} data-label="Starttime">
                <span className="splitLabel">
                    Starttime
                </span>
                {renderDayTime(runner.runner.runnerStartTime || runner.runner.startTime)}
            </td>
            {runner.splits.map(s => this.renderSplit(s, runner, collapsible))}
        </tr>)
    }
}

export default SplitGrid
