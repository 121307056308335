import React from 'react'
import DataService from '../data/DataService'
import {renderTime} from '../helpers/time'


class CategoryIndex extends React.Component {
    componentDidMount() {

    }
    render() {
        return <div className="margin">Choose a category...</div>
    }
}

export default CategoryIndex
